import React, { useState } from 'react';
import './confirm-change-email-modal.css';
import { FormGroup } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateMyEmail } from '@redux/settings/actions';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotPassword } from '@redux/actions';
import { Modal } from '../../../components/ui/Modal';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import Input from '../../../components/ui/Input';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  bindActionCreators({ updateMyEmail, forgotPassword }, dispatch);

type ConfirmChangeEmailModalProps = {
  readonly onHide: () => void;
  readonly show: boolean;
  readonly newEmail: string;
  readonly currentEmail: string;
  updateMyEmail: (data: { email: string; password: string }) => void;
  forgotPassword: (formValues: { email: string }) => void;
};

function ConfirmChangeEmailModal(
  props: ConfirmChangeEmailModalProps
): JSX.Element {
  const {
    show,
    onHide,
    newEmail,
    currentEmail,
    updateMyEmail,
    forgotPassword
  } = props;
  const { t } = useTranslation();

  const [userPassword, setUserPassword] = useState('');

  const handleForgotPassword = (): void => {
    forgotPassword({ email: currentEmail });
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();

    updateMyEmail({ email: newEmail, password: userPassword });
    onHide();
  };
  return (
    <Modal.Root
      animation={false}
      keyboard={true}
      onHide={props.onHide}
      show={show}
    >
      <Modal.Header closeButton onHide={onHide}>
        <p>{t('settings.email.confirm-change')}</p>
      </Modal.Header>
      <form id='confirm-change-email' onSubmit={e => handleSubmit(e)}>
        <Modal.Body>
          <p>
            {t('settings.email.new')}: {newEmail}
          </p>
          <p>{t('settings.email.warning-change-email')}</p>
          <FormGroup controlId='confirm-password'>
            <Input
              id='confirm-password'
              label='Senha'
              type='password'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setUserPassword(e.target.value)
              }
              value={userPassword}
            />
            <Button
              style={{
                padding: 0
              }}
              buttonType={ButtonTypes.Outline}
              onClick={handleForgotPassword}
            >
              <p className='reset-my-password'>
                {t('settings.email.reset-my-password')}
              </p>
            </Button>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            buttonType={ButtonTypes.Primary}
            type='submit'
            form='confirm-change-email'
          >
            {t('settings.buttons.confirm')}
          </Button>
          <Button onClick={onHide} buttonType={ButtonTypes.Secondary}>
            {t('buttons.cancel')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal.Root>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmChangeEmailModal);
